import { useEffect, useState } from 'react';

export function useOnScreen(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  function canUse() {
    return typeof IntersectionObserver !== 'undefined';
  }

  function getObserver() {
    if (canUse()) {
      return new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      });
    }
    return {
      observe: () => {},
      disconnect: () => {},
    };
  }

  const observer = getObserver();

  useEffect(() => {
    if (!canUse()) {
      setIsIntersecting(true);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [ref.current]);

  return isIntersecting;
}
