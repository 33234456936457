import { useCookies } from 'react-cookie';

export function AuthWrapper({ children }) {
  const [cookies, _] = useCookies(['hdAuth']);

  if (!cookies?.hdAuth) {
    return (
      <>
        <div className='google-wrapper'>
          <h2 className='display'>Ready to Hack?</h2>
          <a href='/google'>Log In with Google</a>
        </div>
      </>
    );
  }

  return children;
}
