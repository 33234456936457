import { useRef } from 'react';
import { useOnScreen } from './use-on-screen';

import badPuns from './badpuns.png';
import mosEisley from './mosEisley.png';
import resistance from './resistance.jpg';
import coffee from './coffee.png'
import ooblob from './ooblob.png';

export function LogoSection() {
  const ctaRef = useRef();
  const ctaVisible = useOnScreen(ctaRef);

  return (
    <div ref={ctaRef} className={`logos ${ctaVisible ? 'active' : ''}`}>
      {[mosEisley, badPuns, ooblob, coffee, resistance].map(logo => {
        return <img src={logo} key={logo} className="logo" />;
      })}
    </div>
  );
}

