import { useEffect, useState } from 'react';

export function FaqSection() {
  const allQuestions = ['what', 'why', 'how', 'hat'];
  const [currentlyExpanded, setCurrentlyExpanded] = useState();

  useEffect(() => {
    allQuestions.forEach(id => {
      const el = document.getElementById(id);
      if (id === currentlyExpanded) {
        el.style.maxHeight = `${el.scrollHeight}px`;
      } else {
        el.style.maxHeight = null;
      }
    });
  }, [currentlyExpanded]);

  function toggle(question) {
    if (question === currentlyExpanded) {
      setCurrentlyExpanded('');
    } else {
      setCurrentlyExpanded(question);
    }
  }

  return (
    <div>
      <div className='collapsible'>
        <button className="question display" onClick={() => toggle('what')}>What is a hack day?</button>
        <div className="answer" id="what">
          <p className="text">
            Hack Days at BookBub started several years ago as a time for engineers to work on projects outside of the scope of their normal product work, but still related to Pubmark. Starting about four years ago, we began inviting the broader BB community to participate and contribute to coming up with ideas and shaping the projects, and we've found that it's only made the experience (and end products) better!
            <br />
            <br />
            Practically, we’ll take two days to come together in groups (or individually) to work on a project. These projects can be anything from a fun internal product (ye olde lunchbot, for those who remember it, originated during a hack day) to helpful tooling improvements (bbdocs as you know it was also a hack day project) to a consumer facing feature to just a research project to play around with new tools or technologies. The goal doesn’t have to be a finished feature demo (though it can be!) -- sometimes the outcome looks more like a written proposal or a wireframe.
          </p>
        </div>
      </div>
      <div className='collapsible'>
        <button className="question display" onClick={() => toggle('why')}>Why have a hack day?</button>
        <div className="answer" id="why">
          <p className="text">
            We want to give the team an opportunity to think outside the box, beyond their day to day jobs, and show us what creative ideas they have to offer.
            <br />
            <br />
            They’re also a great time to bring together folks who otherwise might not work directly with each other across the company, and to play around with interesting ideas that might not otherwise get prioritized. Historically, we’ve seen great innovation come out of hack days, including some actual tools that are now a part of BB.
          </p>
        </div>
      </div>
      <div className='collapsible'>
        <button className="question display" onClick={() => toggle('how')}>How can I participate?</button>
        <div className="answer" id="how">
          <div className="text">
            We’d love for people across BB to be able to participate in some way -- your level of commitment can be anywhere from a few minutes to the full two days. If you think your participation might impact your team’s deliverables, please talk to your manager or department head. A few specific roles you can play:
            <ul>
              <li>Tell us what ideas you have! </li>
              <li>Help workshop ideas that other people have proposed, refining them or pushing them in a different direction</li>
              <li>Pair program (if you've been playing around with writing code, you can pair with an engineer to observe them or try out writing some yourself!)</li>
              <li>Lend your expertise to a project, whether that expertise relates to our business, our partners' perspectives, or something else entirely</li>
              <li>Provide feedback on a project that’s underway</li>
              <li>Write code, make designs, or help product manage a project</li>
              <li>Attend a workshop to learn a new skill</li>
              <li>Present a workshop on a skill you already have! </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='collapsible'>
        <button className="question display" onClick={() => toggle('hat')}>What do hats have to do with all this?</button>
        <div className="answer" id="hat">
          <p className="text">
            Legend has it that in the early days of hack days at Pubmark, someone misheard "hack" as "hat," wore a fun hat as a result, and, well, the rest is history.
          </p>
        </div>
      </div>
    </div>
  );
}
