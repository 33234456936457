import { useState } from 'react';
import './app.css';
import { FaqSection } from './faq_section';
import { LogoSection } from './logo_section';
import Confetti from 'react-confetti';

function App() {
  const [showConfetti, setShowConfetti] = useState(false);  

  return (
    <div className="app">
      {showConfetti ? <Confetti /> : null}
      <header className="header">
        Pubmark Hack Days <button className='confetti-button' onClick={() => setShowConfetti(!showConfetti)}>2023</button>
      </header>
      <div className="separator" />
      <div className="paint-image parallax-bg">
        <div className="overlay display">
          <span className="message-content">
            We're bringing Pubmark together to collaborate, innovate, learn, and have fun!
          </span>
        </div>  
      </div>
      
      <div className="interlude plain">
        <p>
          <span className="display">Hack Days</span> are a time for all cats to come together and work on creative ideas that fall outside our day to day jobs, but that we think will add value to Pubmark (whether directly or by helping us hone a new skill).
        </p>
      </div>

      <div className="tree-image parallax-bg">
        <div className="overlay">
          <div className="faq">
            <h2 className="section-title display">Frequently Asked Questions</h2>
            <FaqSection />
          </div>
        </div>
      </div>

      <div className="interlude plain">
        <div>
          <span>Tell us what you'd like to see in this year's Hack Days! Fill out <a href="https://docs.google.com/forms/d/e/1FAIpQLSfuZ_N_tIXzqeHKpTDgPk9mKS4hqc5fszjZtqjWklUqr5KJrw/viewform?usp=send_form" target="_blank">this form</a>.</span>
          <div className="sponsors">
            <div className="separator" />
            <h2 className="section-title display">Proudly sponsored by</h2>
            <LogoSection />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;